import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import { confirmAlert } from "react-confirm-alert"; // Import

import { SlotService } from "../../service/slot.service";
import { Companylist } from "./companylist";




import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

export enum Mode {
  none = "none",
  game = "game",
  slot = "slot",
  casiono = "casionos"
}

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
  game = "game",
  slot = "slot",
  casiono = "casionos"
}

interface Props {
  user: any;
  activeHelp: boolean;
  authenticated: boolean;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;
}

interface State {
  mode: string;
  slots: any;
  games: any;
  withdraws: any;
  deposits: any;
  casionos: any;
  gamename: any;
  gameCompany: any;
  gameMode:any;
  isOpen : boolean
  popupStatuses: string;
}


export class Company extends Component<Props, State> {
   slotService = new SlotService();

   constructor(props: any) {
     super(props);
     this.state = {
       mode: Mode.none,
       slots: [],
       games: [],
       withdraws: [],
       deposits: [],
       casionos: [],
       gamename: "",
       gameCompany: "",
       gameMode: Mode.casiono,
       isOpen : false,
       popupStatuses: popupView.none
     };
   }
 
   componentDidMount() {
     this.slotService.getSlotSetting().then((s) => {
       if (s.status === "success") {
         this.setState({ slots: s.slot, });
       }
     });
 
     
     this.slotService.getCasinoSetting().then((s) => {
       if (s.status === "success") {
         this.setState({ casionos: s.casiono,mode: Mode.casiono  });
       }
     });
   }

   handleClosePopup = () => {
     this.setState({ popupStatuses: popupView.none });
   };

   handleChangePopup = (viewName: string) => {
     this.setState({ popupStatuses: viewName });
   };
 
   handleOpenSlot = (code: string, company : string ) => {
     this.slotService.OpenSlot(code, company).then((data: any) => {
       this.setState({isOpen : false})
       if (data.status === "success") {
         let timn = new Date().getTime();
         window.open(data.gameUrl, "Data", "height=800,width=1400");
       }
     });
   };
   
   handleGame = (name: string) => {
     this.slotService.get_slot_by_company(name).then((s: any) => {
       if (s.status === "success") {
         this.setState({ games: s.games, mode: Mode.game });
       }
     });
   };
 
   RenderSlot = (info: any) => {

     const imgUrl = info.imgUrl
 
     return (
 
         <a className="company-btn company-thumbnail" key={`key${info.nameEn}kkk`} onClick={() => {
           if (info.used === "y") {
             this.setState({gamename:info.code});
             this.setState({gameMode:Mode.slot});
             this.setState({popupStatuses: popupView.game});
           } else {
             confirmAlert({
               title: "점검중",
               message: "현재 해당게임은 점검중입니다 .",
               buttons: [
                 {
                   label: "확인",
                   onClick: () => {},
                 },
               ],
             });
           }
         }}>
           <div className="btn-container">
               <div className="btn-panel">
                 <img className="main-img" src={imgUrl}/>
                 <button className="play-btn game-play">PLAY</button>
               </div>
           </div>
           <span className="name">{info.nameKo}</span>
         </a>
 
     );
   };

   RenderCasino = (info: any) => {

    const imgUrl = info.mobileImg

    return (

        <a className="company-btn company-thumbnail" key={`key${info.nameEn}kkk`} onClick={() => {
          if (info.used === "y") {
            this.handleOpenSlot(info.code, info.gameCompany)
          } else {
            confirmAlert({
              title: "점검중",
              message: "현재 해당게임은 점검중입니다 .",
              buttons: [
                {
                  label: "확인",
                  onClick: () => {},
                },
              ],
            });
          }
        }}>
          <div className="btn-container">
              <div className="btn-panel">
                <img className="main-img" src={imgUrl}/>
                <button className="play-btn game-play">PLAY</button>
              </div>
          </div>
          <span className="name">{info.nameKo}</span>
        </a>

    );
  };

  RenderGame = (info: any) => {

    const imgUrl = info.mobileImg

    return (

        <a className="company-btn company-thumbnail" key={`key${info.nameEn}kkk`} onClick={() => {
          if (info.used === "y") {
            this.handleOpenSlot(info.code, info.apiCompany)
          } else {
            confirmAlert({
              title: "점검중",
              message: "현재 해당게임은 점검중입니다 .",
              buttons: [
                {
                  label: "확인",
                  onClick: () => {},
                },
              ],
            });
          }
        }}>
          <div className="btn-container">
              <div className="btn-panel">
                <img className="main-img" src={imgUrl}/>
                <button className="play-btn game-play">PLAY</button>
              </div>
          </div>
          <span className="name">{info.nameKo}</span>
        </a>

    );
  };



  


  render() {
    
    const authenticated = this.props.authenticated;

    const RenderPopup = () => {
      
      if (this.state.popupStatuses === popupView.game) {
          
        if(this.props.authenticated == false){
          confirmAlert({
            title: "로그인",
            message: "로그인 이후 이용가능한 기능입니다",
            buttons: [
              {
                label: "확인",
                onClick: () => {
                  this.setState({popupStatuses :popupView.none })
                },
              },
            ],
          });
          return;
        }

        return <Companylist handleClose={this.handleClosePopup} handleChange={this.handleChangePopup} gamename={this.state.gamename}></Companylist>;
      }

      return <div></div>;
    };


    if (this.state.mode === Mode.none) {
      return (
        
        <main className="page-content">
            <section className="company-section">
               <div className="container">

               <CircularProgress />

               </div>
            </section>


            <footer className="footer-section">
                <div className="company-logo">
                  <img src="/new/images/pragmatic-lg.png"/>
                </div>
                <div className="company-logo">
                  <div className="container">
                     <img src="/new/images/pragmatic-lg.png"/>
                     <img src="/new/images/habanero-lg.png"/>
                     <img src="/new/images/CQ9-lg.png"/>
                     <img src="/new/images/BNG-lg.png"/>
                     <img src="/new/images/PLAYNGO-lg.png"/>
                     <img src="/new/images/PLAYSTAR-lg.png"/>
                     <img src="/new/images/iSOFTBET-lg.png"/>
                     <img src="/new/images/aristocrat-lg.png"/>
                     <img src="/new/images/ag-lg.png"/>
                     <img src="/new/images/microgaming-lg.png"/>
                     <img src="/new/images/GAMEART-lg.png"/>
                     <img src="/new/images/GENESIS-lg.png"/>
                     <img src="/new/images/toptrend.png"/>
                     <img src="/new/images/tpg.png"/>
                     <img src="/new/images/bbin.png"/>
                     <img src="/new/images/playson-lg.png"/>
                     <img src="/new/images/BGAMING-lg.png"/>
                     <img src="/new/images/EVOPLAY-lg.png"/>
                     <img src="/new/images/realtime-lg.png"/>
                     <img src="/new/images/BBTECH-lg.png"/>
                     <img src="/new/images/DREAMTECH-lg.png"/>
                  </div>
               </div>
            </footer>

            {RenderPopup()}
      </main>

      );
    }

    if (this.state.mode === Mode.game) {
      return (
        
        <main className="page-content">
            <section className="company-section">
               <div className="container">

               {this.state.games.map((row: any) => this.RenderGame(row))}

               </div>
            </section>


            <footer className="footer-section">
              <div className="company-logo">
                  <img src="/new/images/pragmatic-lg.png"/>
                </div>
               <div className="company-logo">
                  <div className="container">
                     <img src="/new/images/pragmatic-lg.png"/>
                     <img src="/new/images/habanero-lg.png"/>
                     <img src="/new/images/CQ9-lg.png"/>
                     <img src="/new/images/BNG-lg.png"/>
                     <img src="/new/images/PLAYNGO-lg.png"/>
                     <img src="/new/images/PLAYSTAR-lg.png"/>
                     <img src="/new/images/iSOFTBET-lg.png"/>
                     <img src="/new/images/aristocrat-lg.png"/>
                     <img src="/new/images/ag-lg.png"/>
                     <img src="/new/images/microgaming-lg.png"/>
                     <img src="/new/images/GAMEART-lg.png"/>
                     <img src="/new/images/GENESIS-lg.png"/>
                     <img src="/new/images/toptrend.png"/>
                     <img src="/new/images/tpg.png"/>
                     <img src="/new/images/bbin.png"/>
                     <img src="/new/images/playson-lg.png"/>
                     <img src="/new/images/BGAMING-lg.png"/>
                     <img src="/new/images/EVOPLAY-lg.png"/>
                     <img src="/new/images/realtime-lg.png"/>
                     <img src="/new/images/BBTECH-lg.png"/>
                     <img src="/new/images/DREAMTECH-lg.png"/>
                  </div>
               </div>
            </footer>

            {RenderPopup()}
      </main>

      );
    }

    return (
      
      <main className="page-content">
             
             {/* <section className="company-section" style={{paddingTop : isMobile ?  '20px' : '150px'}}>
                {
                  this.state.gameMode ===  Mode.slot ? (
                    <img className="main-img" src='new/images/slot_bar_active.png' style={{padding : isMobile ? '10px': '20px', width : isMobile ? '100%' : ''}} onClick={()=> this.setState({gameMode :  Mode.slot})}/>
                    ) :(
                      <img className="main-img" src='new/images/slot_bar.png' style={{padding : isMobile ? '10px' : '20px', width : isMobile ?  '100%' : ''}} onClick={()=> this.setState({gameMode :  Mode.slot})}/>
                      )
                }
                {
                  this.state.gameMode ===  Mode.casiono ? (
                    <img className="main-img" src='new/images/casino_bar_active.png' style={{padding : isMobile ? '10px': '20px', width : isMobile ?  '100%': ''}} onClick={()=> this.setState({gameMode :  Mode.casiono})}/>
                  ) :(
                    <img className="main-img" src='new/images/casino_bar.png' style={{padding : isMobile ? '10px': '20px', width : isMobile ? '100%' : ''}} onClick={()=> this.setState({gameMode :  Mode.casiono})}/>
                  )
                }


            </section> */}


            {
              // this.state.gameMode ===  Mode.slot &&  (
                <section className="company-section" style={{padding : '10px'}}>
                  <div className="container"  >
                         {this.state.slots.map((row: any) => this.RenderSlot(row))}
                  </div>
                </section>
              // )
            }

            {
                      // this.state.gameMode ===  Mode.casiono &&  (
                      <section className="company-section" style={{padding : '10px'}}>
                        <div className="container">
                         {this.state.casionos.map((row: any) => this.RenderCasino(row))}
                        </div>
                     </section>
                      // )
            }


            <footer className="footer-section">
              <div className="company-logo"><img src="/new/images/t_logo.svg"  width="80" height="80"/><span style={{fontSize : '34px'}}>@Khanslot</span></div>
               <div className="company-logo">
                  <div className="container">
                     <img src="/new/images/pragmatic-lg.png"/>
                     <img src="/new/images/habanero-lg.png"/>
                     <img src="/new/images/CQ9-lg.png"/>
                     <img src="/new/images/BNG-lg.png"/>
                     <img src="/new/images/PLAYNGO-lg.png"/>
                     <img src="/new/images/PLAYSTAR-lg.png"/>
                     <img src="/new/images/iSOFTBET-lg.png"/>
                     <img src="/new/images/aristocrat-lg.png"/>
                     <img src="/new/images/ag-lg.png"/>
                     <img src="/new/images/microgaming-lg.png"/>
                     <img src="/new/images/GAMEART-lg.png"/>
                     <img src="/new/images/GENESIS-lg.png"/>
                     <img src="/new/images/toptrend.png"/>
                     <img src="/new/images/tpg.png"/>
                     <img src="/new/images/bbin.png"/>
                     <img src="/new/images/playson-lg.png"/>
                     <img src="/new/images/BGAMING-lg.png"/>
                     <img src="/new/images/EVOPLAY-lg.png"/>
                     <img src="/new/images/realtime-lg.png"/>
                     <img src="/new/images/BBTECH-lg.png"/>
                     <img src="/new/images/DREAMTECH-lg.png"/>
                  </div>
               </div>
            </footer>

            {RenderPopup()}
      </main>

      
    );
  }
}


